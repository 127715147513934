@font-face {
    font-family: 'MyCustomFont';
    src: url('../../../public/fonts/fontawesome-webfont.woff2') format('woff2'),
        url('../../../public/fonts/fontawesome-webfont.woff') format('woff'),
        url('../../../public/fonts/fontawesome-webfont.eot') format('eot'),
        url('../../../public/fonts/fontawesome-webfont.svg') format('svg'),
        url('../../../public/fonts/fontawesome-webfont.ttf') format('ttf'),
        url('../../../public/fonts/FontAwesome.otf') format('otf'),
        url('../../../public/fonts/sas-webfont.woff2') format('woff2'),
        url('../../../public/fonts/sas-webfont.woff') format('woff'),
        url('../../../public/fonts/sas-webfont.ttf') format('ttf'),
        url('../../../public/fonts/sas-webfont.eot') format('eot');
    /* Add additional font formats here if necessary */
    font-weight: normal;
    font-style: normal;
}